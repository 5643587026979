<template>
  <div class="container page">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">Register</div>
                <div class="card-body">
                    <form @submit.prevent="register">
                      <div class="form-group">
                        <label for="name">Name</label>
                        <input type="text" class="form-control" id="name" v-model="name" :disabled="loading" required>
                      </div>
                      <div class="form-group">
                        <label for="email">Email</label>
                        <input type="email" class="form-control" id="email" v-model="email" :disabled="loading" required>
                      </div>
                      <div class="form-group">
                        <label for="password">Password</label>
                        <input type="password" class="form-control" id="password" v-model="password" :disabled="loading" required>
                      </div>
                      <div class="form-group">
                        <label for="passwordConfirmation">Confirm Password</label>
                        <input type="password" class="form-control" id="passwordConfirmation" v-model="passwordConfirmation" :disabled="loading" required>
                      </div>

                      <div v-if="loading" class="text-center">
                        <div class="loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>

                      <template v-if="!loading">
                        <button type="submit" class="btn btn-block btn-primary">Register</button>

                        <div class="text-center">
                          <h4>OR</h4>

                          <div class="social-login">
                            <LoginGoogle type="Register"></LoginGoogle>
                            <LoginPatreonV2 type="Register"></LoginPatreonV2>
                          </div>
                        </div>
                      </template>
                      
                    </form>
                </div>
            </div>
            <p>&nbsp;</p>
            Already have an account? <router-link to="/login">Login now!</router-link>
        </div>
    </div>
  </div>
</template>

<script>
import LoginPatreonV2 from '@/components/LoginPatreonV2.vue';
import LoginGoogle from '@/components/LoginGoogle.vue';

export default {
    name: "Register",
    components: { LoginPatreonV2, LoginGoogle },
    data() {
      return {
        loading: false,
        name: '',
        email: '',
        password: '',
        passwordConfirmation: ''
      };
    },
    methods: {
      register() {
        if (this.password !== this.passwordConfirmation) {
          this.$toast.error('Passwords do not match');
          return;
        }

        this.loading = true;

        const newUser = {
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation
        };

        this.$http.post('/register', newUser)
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.loading = false;
            window.location.href = '/';
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            // Registration failed
            // Handle the error as needed
            this.loading = false;
            console.error(error);
          });
      }
    }
}
</script>

<style lang="scss" scoped>
h4 {
  margin: 20px 0;
}

#google_btn {
  margin-bottom: 10px;
}
</style>
