<template>
<span>
  <a class="btn btn-danger btn-block" href="https://api.cthulhuarchitect.com/social-auth/patreon/redirect">
    <img :alt="type + ' using Patreon'" width="16" height="16" class="patreon-icon" src="/images/patreon.png" loading="lazy"/> {{ type }} with Patreon
  </a>
</span>


</template>

<script>
export default {
  name: 'LoginPatreon',
  props: {
    type: { type:String, default: 'Login' }
    },
  methods: {
  },
  computed: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.patreon-icon {
  margin-right:4px;
  position:relative;
}
</style>
