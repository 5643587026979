<template>
  <div class="container page">

    <div class="content">
      

      <div class="row" v-if="!loading">
        <div class="col-6">
          <h2>My Profile</h2>
          <p class="lead">Here you can change the details related to your account.</p>

          <p>&nbsp;</p>
          <form @submit.prevent="profile">
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" class="form-control" id="name" v-model="name" placeholder="Enter your name">
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" class="form-control" id="email" v-model="email" placeholder="Enter email">
            </div>
            <button type="submit" class="btn btn-block btn-primary" @click="saveChanges">Save</button>
          </form> 
        </div>
        <div class="col-6">
          <img class="img-fluid img-cthulhu" src="/cthulhu_500.webp" fetchpriority="high" width="500" height="500" alt="Cthulhu Architect Wants You!"/>
          <p>&nbsp;</p>
          <p class="text-center"><a href="https://discord.gg/2tgye4fWpZ" target="_blank">Join me on my discord server</a> and send me your bugs, ideas and suggestions</p>
        </div>
      </div>

      <div class="text-center" v-if="loading">
        <div class="loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
    name: "Profile",
    components: {  },
    data() {
      return {
        loading: true,
        name: '',
        email: '',
        password: ''
      };
    },
    created() {
      this.$http.get('/user').then(response => {
        this.name = response.data.name;
        this.email = response.data.email;
        this.loading = false;
      });
    },
    methods: {
      saveChanges() {
        this.$http.put('/user/profile-information', {
          name: this.name,
          email: this.email
        }).then(() => {
          this.$toast.success('Profile saved successfully!');
        });
      }
    }
}
</script>

<style lang="scss" scoped>
  .page {
    font-size:20px;
    line-height:28px;
    background:#fff;
    margin-top:20px;
    margin-bottom:20px;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.15);
    box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.15);
  }

  h2 {
    margin-bottom: 20px;
  }

  .content {
    padding: 30px 50px;
  }

  .lead {
    margin-bottom:60px;
  }
</style>
